import * as I from 'immutable';
import React from 'react';

import {GraphTimeRange} from 'app/components/AnalyzePolygonChart/types';
import {
  FeatureStatsApiResponse,
  useMultiFeatureAnalysisStats,
} from 'app/components/Dashboard/hooks/useAggregatedFeatureStats';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {DataLayerInfo} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';

import {MOCK_RESPONSE, MOCK_TIME_RANGE} from '../mockData'; // Import mock data
import MultiFeatureAnlaysisLineChart from './MultiFeatureAnalysisChart';
import MultiFeatureAnlaysisTable from './MultiFeatureAnalysisTable';
import {makeAverageFeatureDatasets, makeMultiFeatureDatasets} from '../utils/chartUtils';
export type FlatGraphPoint = [string, number, string];

const layer = layerUtils.getLayer('S2_NDVI') as DataLayerInfo;

export const MultiFeatureAnalysisWidget: React.FC<{
  features: I.ImmutableOf<ApiFeature[]>;
}> = ({features}) => {
  const rawFeatures = React.useMemo(() => features.toJS() as ApiFeature[], [features]);
  const [featureIds] = React.useState<string[] | null>(Object.keys(MOCK_RESPONSE));
  const [selectedTimeRange] = React.useState<GraphTimeRange | null>(MOCK_TIME_RANGE);
  // TODO: will the frontend control the statName or is the backend always giving us back
  // a specific stat for a given layer? (eg, mean for data layers, area sum for category?)
  const [statName] = React.useState<'mean' | string | null>('mean');
  const [timeAggregation] = React.useState<'monthly' | 'yearly'>('monthly');
  // TODO: tbd on if we can do different types of top-level aggregation on the frontend?
  const [frontendAggregation] = React.useState<'sum' | 'mean'>('sum'); // Adjusted to match

  //TODO: This is where we will control the current set of currently displayed datasets.
  //We will need to pass the setter logic here to both the chart and the table, so that
  //we can affect the chart by interacting with the table (and vice versa?)
  const [showAggregateTrend, setShowAggregateTrend] = React.useState(false);
  const [displayFeatureIds, setDisplayFeatureIds] = React.useState<string[]>(
    //TODO(eva): we probably just want to show the top-level aggregation first rather than all features.
    featureIds?.filter((id) => !!id) || []
  );

  const {data, isLoading, error, isFetching} = useMultiFeatureAnalysisStats({
    featureIds: featureIds!,
    layerKey: layer.key,
    timeRange: selectedTimeRange!,
    statName: statName!,
    timeAggregation: timeAggregation!,
    frontendAggregation: frontendAggregation!,
  });

  // Controllers //
  const multiFeatureDatasets = React.useMemo(() => {
    if (!data) return null;
    const filteredData: FeatureStatsApiResponse = {};
    for (const featureId in data) {
      if (displayFeatureIds.includes(featureId)) {
        filteredData[featureId] = data[featureId];
      }
    }

    return displayFeatureIds.length === 0
      ? makeMultiFeatureDatasets(data, rawFeatures)
      : makeMultiFeatureDatasets(filteredData, rawFeatures);
  }, [data, rawFeatures, displayFeatureIds]);

  // TODO: the type of aggregation we do here will depend on the dataset. for category
  // we'll want to sum area of each category, rather than means.
  const topLevelTrendDatasets = React.useMemo(
    () => (data ? makeAverageFeatureDatasets(data) : null),
    [data]
  );

  return (
    <>
      {/* Basic loading/error display */}
      {isLoading && <div>Loading...</div>}
      {isFetching && <div>Fetching...</div>}
      {error && <div>Error: {error.message}</div>}

      <button onClick={() => setShowAggregateTrend((prev) => !prev)}>
        {showAggregateTrend ? 'Hide ' : 'Show '}top level trend
      </button>

      {multiFeatureDatasets && featureIds && (
        <MultiFeatureAnlaysisLineChart
          onClick={() => null}
          layerInfo={layer}
          datasets={
            showAggregateTrend && !!topLevelTrendDatasets
              ? [...topLevelTrendDatasets, ...multiFeatureDatasets]
              : multiFeatureDatasets
          }
          onInitialize={() => null}
          onPanComplete={() => null}
          onZoomComplete={() => null}
          isExpanded={false}
          dataRange={layer.dataRange}
          xAxisAggregation={timeAggregation}
        />
      )}

      {featureIds && (
        <MultiFeatureAnlaysisTable
          featureIds={featureIds}
          displayFeatureIds={displayFeatureIds}
          setDisplayFeatureIds={setDisplayFeatureIds}
          features={rawFeatures}
        />
      )}
    </>
  );
};
