import {ChartData} from 'chart.js';
import moment from 'moment';

import {ApiFeature} from 'app/modules/Remote/Feature/types';

import {FeatureStatsApiResponse} from '../hooks/useAggregatedFeatureStats';

export type MultiFeatureScatterDataPoint = {x: number; y: number; date: Date; featureId: string};

/**Used for taking a response to the /statistics endpoint, and converting
 * it into one dataset per feature
 */
export const makeMultiFeatureDatasets = (
  featureStats: FeatureStatsApiResponse,
  //TODO: we probably do not want to have full ApiFeatures here, maybe just a dictionary with id
  // and name plus any other feature properties we might need

  features: ApiFeature[]
): ChartData<'line', MultiFeatureScatterDataPoint[]>['datasets'] => {
  return Object.entries(featureStats).map(([featureId, featureStats]) => {
    const lineColor = hashStringToColor(featureId);
    const lineColorWithOpacity = lineColor.replace('rgb', 'rgba').replace(')', ', 0.1)');
    const rawFeature = features.find((f) => f.id.toString() === featureId);
    return {
      data: Object.entries(featureStats).map(([date, value]) => {
        return {
          x: moment(date).valueOf(),
          y: value,
          featureId: featureId,
          date: new Date(date),
        } as MultiFeatureScatterDataPoint;
      }),
      label: rawFeature?.properties.name || featureId,
      borderColor: lineColorWithOpacity,
      backgroundColor: lineColor,
      pointBackgroundColor: lineColor,
      pointBorderColor: lineColor,
      fill: false, // To avoid filling the area under the line
    };
  });
};

/**Used for taking a response to the /statistics endpoint, and collapsing
 * it down to a single timeseries averaged across all points.
 */
export const makeAverageFeatureDatasets = (
  featureStats: FeatureStatsApiResponse
): ChartData<'line', MultiFeatureScatterDataPoint[]>['datasets'] => {
  // Get all unique timestamps across all features
  const allTimestamps = new Set<string>();
  Object.values(featureStats).forEach((timeseriesData) => {
    Object.keys(timeseriesData).forEach((timestamp) => {
      allTimestamps.add(timestamp);
    });
  });

  // Calculate average for each timestamp
  const result: Record<string, number> = {};
  allTimestamps.forEach((timestamp) => {
    let sum = 0;
    let count = 0;

    // Sum up all values for this timestamp
    Object.values(featureStats).forEach((timeseriesData) => {
      const value = timeseriesData[timestamp];
      if (value !== undefined) {
        sum += value;
        count++;
      }
    });

    // Calculate and store average
    result[timestamp] = count > 0 ? sum / count : 0;
  });

  const lineColor = 'red';
  const lineColorWithOpacity = 'rgba(255, 0, 0, 0.6)';
  const averageTrendLineDatasets = [
    {
      data: Object.entries(result).map(([date, value]) => {
        return {
          x: moment(date).valueOf(),
          y: value,
          featureId: 'average',
          date: new Date(date),
        } as MultiFeatureScatterDataPoint;
      }),
      label: 'average',
      borderColor: lineColorWithOpacity,
      backgroundColor: lineColor,
      pointBackgroundColor: lineColor,
      pointBorderColor: lineColor,
    },
  ];
  return averageTrendLineDatasets;
};

/**Function to have stable colors for each feature for now. */
function hashStringToColor(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const r = Math.abs((hash & 0xff0000) >> 16);
  const g = Math.abs((hash & 0x00ff00) >> 8);
  const b = Math.abs(hash & 0x0000ff);
  return `rgb(${r}, ${g}, ${b})`;
}
