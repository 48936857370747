import {
  ActiveElement,
  Chart,
  ChartData,
  ChartEvent,
  CoreChartOptions,
  LegendItem,
  Plugin,
  PluginOptionsByType,
  ScaleChartOptions,
  ScaleOptionsByType,
  ScatterDataPoint,
  TooltipCallbacks,
  TooltipItem,
} from 'chart.js';
import {IErrorBarXYDataPoint} from 'chartjs-chart-error-bars';
import {groupBy} from 'lodash';
import moment from 'moment';
import React, {CSSProperties} from 'react';

import {
  ByYearGraphRange,
  DataRange,
  Graph,
  GraphByUnit,
  GraphColumn,
  GraphPoint,
  GraphTimeRange,
  GraphWithTimeRange,
  NoteGraphDataRange,
} from 'app/components/AnalyzePolygonChart/types';
import {GraphMode} from 'app/components/AnalyzePolygonPopup/AnalyzePolygonPopup';
import {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import * as colorUtils from 'app/utils/colorUtils';
import * as C from 'app/utils/constants';
import {getWaterYear} from 'app/utils/conversionUtils';
import * as hookUtils from 'app/utils/hookUtils';
import {DataLabels} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import * as mathUtils from 'app/utils/mathUtils';

import {YOY_X_AXIS_YEAR} from './ByYearChart';
import {
  CustomGraphRange,
  CustomNoteGraphRange,
  NoteGraph,
  NoteGraphPoint,
  NoteGraphTimeRange,
} from './types';

/** Chart.js allows arbitary properties to be set on data points. */
export type DataPoint = (ScatterDataPoint | IErrorBarXYDataPoint) & {date: Date; cursor: string};

export const X_SCALE_ID = 'x';
export const Y_SCALE_ID = 'y';

/** Alpha channel value for 25% transparency. */
export const ALPHA_25 = '40';

export const BORDER_COLOR = colorUtils.default.lightGray;

export const BORDER_WIDTH = 1;
export const CURSOR_WIDTH_ACTIVE = 1.5;
export const CURSOR_WIDTH_INACTIVE = 1;

export const TOOLTIP_TIME_FORMAT = 'MM/DD/YYYY';

const FONT_FAMILY = 'Lato';
export const GRAPH_FONT_SIZE = 10;
export const EXPANDED_GRAPH_FONT_SIZE = 14;
export const FONTS = [`${GRAPH_FONT_SIZE}px ${FONT_FAMILY}`];

export const FIT_DISABLED_MODES: GraphMode[] = [
  'aggregateCarbonSalo',
  'aggregateCarbonSpaceIntelligence',
];

/**
 * Utility hook to initialize and update a Chart.js line chart instance.
 */
export function useLineChart({
  datasets,
  labels,
  annotationPluginOptions,
  legendPluginOptions,
  tooltipPluginOptions,
  zoomPluginOptions,
  titlePluginOptions,
  xScaleOptions,
  yScaleOptions,
  extraScaleOptions,
  plugins,
  onClick,
  onInitialize,
  devicePixelRatio,
  isExpanded,
}: {
  datasets: ChartData['datasets'];
  labels: Date[];
  annotationPluginOptions: PluginOptionsByType<'line'>['annotation'];
  legendPluginOptions: PluginOptionsByType<'line'>['legend'];
  tooltipPluginOptions?: PluginOptionsByType<'line'>['tooltip'];
  zoomPluginOptions: PluginOptionsByType<'line'>['zoom'];
  titlePluginOptions: Partial<PluginOptionsByType<'line'>['title']>;
  xScaleOptions: ScaleOptionsByType<'time'>;
  yScaleOptions: ScaleOptionsByType<'linear'>;
  extraScaleOptions?: ScaleChartOptions['scales'];
  plugins?: Plugin[];
  onClick?: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => void;
  onInitialize?: (chart: Chart) => void;
  devicePixelRatio?: number;
  isExpanded: boolean;
}): {
  chart: Chart | null;
  canvasRefCallback: (node: HTMLCanvasElement | null) => void;
} {
  const chartRef = React.useRef<Chart | null>(null);

  /** Change cursor to pointer on clickable data point hover. */
  const onHover = React.useCallback<CoreChartOptions<'line'>['onHover']>(
    (_event, elements, chart) => {
      if (onClick) {
        chart.canvas.style.cursor = elements.length ? 'pointer' : 'unset';
      }
    },
    [onClick]
  );

  const canvasRefCallback = React.useCallback(
    (node: HTMLCanvasElement | null) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        // TODO(anthony): Hack to prevent event processing errors when shift clicking AA dataPoints. Did something change with this ~October 3, 2023?
        // @ts-ignore-next-line
        chartRef.current.legend = {handleEvent: () => {}};
        chartRef.current = null;
      }
      if (node) {
        const ctx = node.getContext('2d');

        if (ctx) {
          Chart.defaults.font.family = `${FONT_FAMILY}, Helvetica, Arial, sans-serif`;
          Chart.defaults.font.size = isExpanded ? EXPANDED_GRAPH_FONT_SIZE : GRAPH_FONT_SIZE;
          Chart.defaults.color = colorUtils.default.darkestGray;
          const chart = new Chart(ctx, {
            // Note: we can show uncertainties by switching this to `lineWithErrorBars`.
            // This was feature flagged but the flag was removed as e did not want to
            // call auth as this component is used in share links.
            type: 'line',
            data: {
              datasets,
              labels,
            },
            options: {
              animation: {
                duration: 0,
              },
              devicePixelRatio,
              maintainAspectRatio: false,
              plugins: {
                filler: {
                  propagate: true,
                },
                annotation: annotationPluginOptions,
                legend: legendPluginOptions,
                tooltip: tooltipPluginOptions,
                zoom: zoomPluginOptions,
                title: titlePluginOptions,
              },
              scales: {
                [X_SCALE_ID]: xScaleOptions,
                [Y_SCALE_ID]: yScaleOptions,
                ...extraScaleOptions,
              },
              onClick,
              onHover,
            },
            plugins,
          });

          if (onInitialize) {
            onInitialize(chart);
          }

          chartRef.current = chart;
        }
      }
    },
    // don't use the dependencies here to trigger updates or you might reset user-selected
    // chart data like legend item toggling. instead use the useUpdateEffect hook.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current) {
      Chart.defaults.font.size = isExpanded ? EXPANDED_GRAPH_FONT_SIZE : GRAPH_FONT_SIZE;
      chartRef.current.update();
    }
  }, [isExpanded]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current) {
      chartRef.current.data.datasets = datasets;
      chartRef.current.update();
    }
  }, [datasets]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current) {
      chartRef.current.data.labels = labels;
      chartRef.current.update();
    }
  }, [labels]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options) {
      chartRef.current.options.onClick = onClick;
      chartRef.current.update();
    }
  }, [onClick]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options) {
      chartRef.current.options.onHover = onHover;
      chartRef.current.update();
    }
  }, [onHover]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options.plugins) {
      chartRef.current.options.plugins.annotation = annotationPluginOptions;
      chartRef.current.update();
    }
  }, [annotationPluginOptions]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options.plugins) {
      chartRef.current.options.plugins.legend = legendPluginOptions;
      chartRef.current.update();
    }
  }, [legendPluginOptions]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options.plugins) {
      chartRef.current.options.plugins.tooltip = tooltipPluginOptions;
      chartRef.current.update();
    }
  }, [tooltipPluginOptions]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options.plugins) {
      chartRef.current.options.plugins.zoom = zoomPluginOptions;
      chartRef.current.update();
    }
  }, [zoomPluginOptions]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options.scales) {
      chartRef.current.options.scales[X_SCALE_ID] = xScaleOptions;
      chartRef.current.update();
    }
  }, [xScaleOptions]);

  hookUtils.useUpdateEffect(() => {
    if (chartRef.current?.options.scales) {
      chartRef.current.options.scales[Y_SCALE_ID] = yScaleOptions;
      chartRef.current.update();
    }
  }, [yScaleOptions]);

  hookUtils.useUpdateEffect(() => {
    if (extraScaleOptions) {
      Object.keys(extraScaleOptions).forEach((id) => {
        if (chartRef.current?.options.scales) {
          chartRef.current.options.scales[id] = extraScaleOptions[id];
          chartRef.current.update();
        }
      });
    }
  }, [extraScaleOptions]);

  return {chart: chartRef.current, canvasRefCallback};
}

/**
 * Make a unique x-axis IDs for each year.
 */
export function makeXAxisID(label: string | number) {
  return X_SCALE_ID + '-' + label;
}

/**
 * Custom plugin that adds a tooltip to the y-axis. Returns a render function and the plugin.
 *
 * Tooltip text is passed when the plugin is created. Renders an absolutely positioned div
 *  containing the provided text.
 */
export function useYAxisTooltip(graph: Graph | GraphWithTimeRange): {
  renderAxisTooltip: () => JSX.Element | null;
  hoverYAxisPlugin: Plugin<'line'>;
} {
  const [tooltipCoordinates, setTooltipCoordinates] = React.useState<[number, number] | null>(null);
  // Range labels can be nominal, or numeric with units. If we don't have either of these, leave null.
  let [rangeStart, rangeEnd]: DataLabels | [null, null] = [null, null];
  if (graph.layerDataLabels) {
    [rangeStart, rangeEnd] = graph.layerDataLabels;
  }

  const tooltipText =
    rangeStart && rangeEnd ? `Values range from ${rangeStart} to ${rangeEnd}` : null;

  const hoverYAxisPlugin = React.useMemo<Plugin<'line'>>(
    () => ({
      id: 'hover-y-axis',
      afterEvent: (chart, {event: {type, y, x}}) => {
        if (
          !FIT_DISABLED_MODES.includes(graph.graphMode) &&
          (type === 'mouseenter' || type === 'mousemove') &&
          y !== null &&
          x !== null
        ) {
          // These coordinates represent the edges of the y axis
          const {top, bottom, left, right} = chart.scales.y;
          if (y > top && y < bottom && x > left && x < right) {
            setTooltipCoordinates([x, y]);
          }
        } else if (type === 'mouseout') {
          setTooltipCoordinates(null);
        }
      },
    }),
    [graph.graphMode]
  );

  // Pixel values; positive x -> right offset
  const tooltipXOffset = 15;

  const renderAxisTooltip = React.useCallback(
    (styles?: CSSProperties) => {
      if (tooltipCoordinates && tooltipText && !FIT_DISABLED_MODES.includes(graph.graphMode)) {
        const [x, y] = tooltipCoordinates;
        return (
          <div
            style={{
              position: 'absolute',
              left: `${x + tooltipXOffset}px`,
              top: `${y}px`,
              borderRadius: '4px',
              padding: '4px',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: colorUtils.default.white,
              ...styles,
            }}
          >
            {tooltipText}
          </div>
        );
      } else {
        return null;
      }
    },
    [graph.graphMode, tooltipCoordinates, tooltipText]
  );

  return {renderAxisTooltip, hoverYAxisPlugin};
}

/** Utility hook to track legendItems to render a custom html legend */
export function useHtmlLegend() {
  const [unstableLegendItems, setLegendItems] = React.useState<LegendItem[] | null>(null);
  const legendItems = hookUtils.useContinuity(unstableLegendItems);

  // Update the legendItems whenever there is an update in the chart
  const htmlLegendPlugin = React.useMemo<Plugin<'line' | 'bar'>>(
    () => ({
      id: 'htmlLegend',
      afterUpdate(chart) {
        setLegendItems(chart.legend?.legendItems || null);
      },
    }),
    []
  );

  const renderLegend = React.useCallback(
    (
      legendItems: LegendItem[] | null,
      isLegendInteractive: boolean,
      isExpanded: boolean,
      handleLegendClick: (item: LegendItem, legendItems?: LegendItem[] | null) => void,
      handleLegendMouseover?: (datasetIndex: number) => void,
      handleLegendMouseout?: () => void,
      style?: CSSProperties
    ) => {
      if (legendItems) {
        return (
          <ul
            // inlining styles for this element and its children
            // (and using px) so that the report's PrintCss can read
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              fontSize: isExpanded ? `${EXPANDED_GRAPH_FONT_SIZE}px` : `${GRAPH_FONT_SIZE}px`,
              gap: '8px',
              justifyContent: 'center',
              ...style,
            }}
            id={'legend-list'}
          >
            {legendItems.map((item) => {
              // In notes we only render things that aren't hidden, but in popup
              // we want to render hidden items that are interactive.
              if (item.text && (isLegendInteractive || !item.hidden)) {
                return (
                  <li
                    key={item.text}
                    onClick={() => {
                      if (isLegendInteractive) {
                        handleLegendClick(item, legendItems);
                      }
                    }}
                    onMouseOver={() => {
                      if (isLegendInteractive && handleLegendMouseover) {
                        handleLegendMouseover(item.datasetIndex);
                      }
                    }}
                    onMouseOut={() => {
                      if (isLegendInteractive && handleLegendMouseout) {
                        handleLegendMouseout();
                      }
                    }}
                    style={{
                      textDecoration: item.hidden ? 'line-through' : 'none',
                      cursor: isLegendInteractive ? 'pointer' : 'default',
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                      color: colorUtils.default.darkestGray,
                      // Give a set width to the none/all toggle so it
                      // doesn't cause things to move when the text changes length
                      ...(item.datasetIndex === null
                        ? {width: !isExpanded ? '40px' : '60px'}
                        : null),
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: item.fillStyle as string,
                        border: item.fillStyle === '#ffffff' ? 'solid 1px' : 'none',
                        width: '12px',
                        height: '12px',
                        borderRadius: '4px',
                      }}
                    />
                    {item.text}
                  </li>
                );
              } else {
                // We have ocassional pixel values appearing that don't correspond to
                // any values in the colormap. They are all near 0 and don't affect what
                // the bar graphs visually look like. We want to not render a legend item
                // for these since we have no color or label.
                return <></>;
              }
            })}
          </ul>
        );
      } else {
        return null;
      }
    },
    []
  );

  return {legendItems, htmlLegendPlugin, renderLegend};
}

/**
 * Format a value from 0 to 1 as a percent.
 */
export function formatPercent(value: number): string {
  return (value * 100).toFixed(0) + '%';
}

/**
 * Format values with units as strings.
 */

export function formatAreaInRangeUnits(value: number | string): string {
  const _value: number = typeof value === 'string' ? parseInt(value) : value;
  return formatPercent(_value);
}

export function formatUnits(value: number | string, unit?: string): string {
  // Handle value is string
  const _value: number = typeof value === 'string' ? parseInt(value) : value;
  // If no units are specified for this data source, stringify our value and return that.
  switch (unit) {
    case undefined:
      return `${_value}`;
    case 'MgC':
      return (
        Intl.NumberFormat('en-US', {
          notation: 'compact',
        } as Intl.NumberFormatOptions).format(_value) + ` ${unit}`
      );
    default:
      return `${_value.toFixed(2)} ${unit}`;
  }
}

/**
 * Format area-in-range values, which are expressed as an area in the
 * organization’s area unit and as a percent of the polygon’s area.
 */
export function formatAreaYValue(
  value: number,
  areaInM2: number,
  areaUnit: ApiOrganizationAreaUnit
): string {
  const areaWithUnit = mathUtils.formatMeasurement({
    value: areaInM2 * value,
    unit: C.UNIT_AREA_M2,
    unitTo: areaUnit,
  }).valueWithUnit;
  const percent = formatPercent(value);
  return areaWithUnit + ' (' + percent + ')';
}

/**
 * Returns a formatted data point y-value.
 */
export function formatYValue(
  value: number,
  graphMode: GraphMode,
  areaInM2: number,
  areaUnit: ApiOrganizationAreaUnit
) {
  switch (graphMode) {
    case 'aggregateCarbonSalo':
    case 'aggregateCarbonSpaceIntelligence':
      return formatUnits(value, 'MgC');

    case 'areaByCategory':
    case 'area':
      return formatAreaYValue(value, areaInM2, areaUnit);

    case 'average':
    case 'averageMonthly':
    default:
      return value.toFixed(2);
  }
}

/**
 * Make the tooltip label callback, which returns a formatted data point y-value
 * to be rendered inside the tooltip.
 */
export function makeTooltipLabelCallback(
  graph: Graph | GraphWithTimeRange,
  areaInM2: number,
  areaUnit: ApiOrganizationAreaUnit
): TooltipCallbacks<'bar' | 'line'>['label'] {
  const tooltipLabelCallback: TooltipCallbacks<'bar' | 'line'>['label'] = (tooltipItem) => {
    if (tooltipItem.dataset.xAxisID == makeXAxisID('trend')) {
      return '';
    }
    const {y} = tooltipItem.parsed;
    return formatYValue(y, graph.graphMode, areaInM2, areaUnit);
  };

  return tooltipLabelCallback;
}

/**
 * Make the tooltip afterLabel callback, which displays helper text for a label. Returns
 * a line for annual mosaics clarifying that the point represents a year's worth of data.
 */
export function makeTooltipFooterCallback(
  graph: Graph | GraphWithTimeRange
): TooltipCallbacks<'bar' | 'line'>['afterLabel'] {
  const tooltipFooterCallback: TooltipCallbacks<'bar' | 'line'>['afterLabel'] = (
    tooltipItem: TooltipItem<'bar' | 'line'>
  ) => {
    const layerConfig = layerUtils.getLayer(graph.layerKey);
    const {x} = tooltipItem.parsed;

    if (layerConfig.mosaic === 'annual') {
      return `Contains data from Jan 1 to ${moment(x).utc().endOf('year').format('MMM D, YYYY')}`;
    } else if (typeof layerConfig.mosaic === 'function') {
      const [startDate, endDate] = layerConfig.mosaic(new Date(x));
      return `Contains data from ${moment(startDate).format('MMM D, YYYY')} to ${moment(endDate).format('MMM D, YYYY')}`;
    }

    return '';
  };
  return tooltipFooterCallback;
}

/**
 * Checks if data ranges are equal.
 */
export function dataRangesEqual(rangeA: DataRange, rangeB: DataRange): boolean {
  if (!rangeA || !rangeB) {
    return false;
  }
  return rangeA[0] === rangeB[0] && rangeA[1] === rangeB[1];
}

export function fitCategoryDataRange(
  graph: Graph | GraphWithTimeRange,
  dataSets: GraphColumn[] | null
): DataRange {
  // Define defaults for these values
  const dataMin = 0;
  let dataMax = 1;
  // If we dont have data sets, we can't filter anything and can't fit anything.
  if (!dataSets || dataSets.length < 1) {
    return [dataMin, dataMax];
  }
  // Prepare chart categories for easier lookup later on
  const dataSetsByCategory: Record<string, GraphColumn> = Object.fromEntries(
    dataSets.map((dataSet) => [dataSet.category, dataSet])
  );
  // Organize graph.dataPoints by some x value, which corresponds to our x-axis
  const dataPointsByDate: Record<string, GraphPoint[]> = groupBy(graph.dataPoints, ([date]) =>
    date.getFullYear()
  );
  /**
   * Our approach here is to set our maximum to the lowest value and reassign
   * whenever we find a category with a higher maximum, thereby finding the series
   * maximum. We can probably pare this down a bit, but this format is easier to
   * conver to a debug value.
   * */
  dataMax = dataMin;
  Object.entries(dataPointsByDate).forEach(([, points]) => {
    const sum = points
      .filter(([, , {category = ''}]) => dataSetsByCategory[category]?.isSelected)
      .map(([, value]) => value)
      .reduce((sum, value) => sum + value, 0);
    if (dataMax < sum) {
      dataMax = sum;
    }
  });

  return [dataMin, dataMax];
}

export function getDefaultDataRange(graph: Graph | GraphWithTimeRange): DataRange {
  switch (graph.graphMode) {
    case 'aggregateCarbonSalo':
    case 'aggregateCarbonSpaceIntelligence': {
      // Rarely a data point will have a NaN value due to an issue retrieving or calculating the data.
      // We want to filter these values out before Math.max otherwise our ranges will also be NaN.
      const dataPoints = graph.dataPoints.filter(([, value]) => value).map(([, value]) => value);
      const dataMax = Math.max(...dataPoints, 0);
      /** y-axis maximum is largest y-value plus 10% as buffer. */
      return [0, dataMax * 1.1];
    }

    // It's possible for us to end up in an intermediate state where the layerDataRange on the graph is
    // not set, this corrects itself in the next render, but causes issues when data fitting on the y-axis
    // turned on. Fall back to [0, 1] as a default in the meantime to prevent crashing
    case 'average':
    case 'averageMonthly':
      return graph.layerDataRange || [0, 1];

    case 'area':
    case 'areaByCategory':
    default:
      return [0, 1];
  }
}

// See if year is selected based on legend selection, stored graph data, or defaults.
// Note: This is brittle. Adjust with care.
export function isYearSelected(
  year,
  selectedByColumn: {[column: string]: boolean},
  getDataColumn: (datasetName: string | number) => GraphColumn | undefined
) {
  // Determine if the year was selected on the legend
  const isYearSelected = year in selectedByColumn ? selectedByColumn[year] : null;
  // If the year was selected via the legend, or if the stored graph has it, or the
  // default, which is true, since all years start enabled.
  return isYearSelected ?? getDataColumn(year)?.isSelected ?? true;
}

// Maps year over year data to lines that the graph uses
export function yearsToLineData(
  years: string[],
  dataPointsByYear: GraphByUnit,
  yearSelection: {[year: string]: boolean},
  hideTooltip: boolean | undefined,
  getColor: (year: number | string) => string | undefined
) {
  return years.map((year) => {
    const color = getColor(year);
    // Selections exist if chart is editable. See if year has been selected.
    return {
      xAxisID: makeXAxisID(year),
      data: dataPointsByYear[year],
      label: year,
      hidden: !yearSelection[year],
      backgroundColor: color,
      pointBackgroundColor: color,
      pointBorderColor: color,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color,
      pointHoverRadius: hideTooltip ? 3 : 5,
      borderColor: color,
      borderWidth: BORDER_WIDTH,
    };
  });
}

/**
 * Gets the data range as [min, max] for cumulative year over year values.
 */
export function getCumulativeRange(cumulativeByYear: GraphByUnit): DataRange {
  const years = Object.keys(cumulativeByYear);

  const [min, max] = years.reduce<DataRange>(
    ([overallMinimum, overallMaximum], year) => {
      // We need to consider the entire range of values or we may not account for local maxima or minima
      // on layers that have ranges on both sides of 0 and with extreme median values.
      const [localMinimum, localMaximum] = cumulativeByYear[year].reduce(
        ([min, max], {y}) => [y < min ? y : min, y > max ? y : max],
        [Infinity, -Infinity]
      );

      // Replace our overall bounds if our locals are greater.
      return [
        localMinimum < overallMinimum ? localMinimum : overallMinimum,
        localMaximum > overallMaximum ? localMaximum : overallMaximum,
      ];
    },
    [Infinity, -Infinity]
  );

  /**
   * Extend our range by 5% on either side for usability's sake.
   *
   * Worth keeping in mind here that ranges can look like the following: [-5, -1] or [0, 20] or [-5, 5]
   */
  const buffer = (max - min) * 0.05;
  const withBuffer: DataRange = [min - buffer, max + buffer];

  return withBuffer;
}

/**
 * Get the graph’s initial data range (i.e., y-axis minimum and maximum values).
 */
export function getDataRange(
  graph: Graph | GraphWithTimeRange,
  currentTimeRange: GraphTimeRange | null,
  shouldFit: boolean,
  dataSets: GraphColumn[] | null,
  currentDataRange: DataRange
): DataRange {
  if (shouldFit && currentTimeRange) {
    if (graph.graphMode === 'areaByCategory') {
      return fitCategoryDataRange(graph, dataSets || null);
    } else {
      return fitDataRange(graph, currentTimeRange, currentDataRange, dataSets);
    }
  } else {
    return getDefaultDataRange(graph);
  }
}

/**
 * Returns a data range fit to the maximum and minimum data values (y-axis) for the given time range (x-axis).
 *
 * Includes a buffer, which will nudge the ranges upward and downward so that points don't run edge to edge.
 *
 * alternateLimits are used when we're caluclating ranges for a graph mode that doesn't use the layer's data ranges, i.e. area.
 */
function fitDataRange(
  graph: Graph | GraphWithTimeRange,
  currentTimeRange: GraphTimeRange,
  currentDataRange: DataRange,
  dataSets?: GraphColumn[] | null
): DataRange {
  const Y_BUFFER_AMOUNT = 0.1;

  // If we are in an Area in Range chart we want to prefer the default dataRange rather than the layerDataRange since
  // that does not represent the area in range data. Otherwise we prefer the layerDataRange and fall back to the default
  const graphDataRange =
    graph.graphMode === 'area'
      ? getDefaultDataRange(graph) || graph.layerDataRange
      : graph.layerDataRange || getDefaultDataRange(graph);

  const [minLimit, maxLimit] = graphDataRange;
  const valuesWithinTimeRange = getValuesWithinTimeRange(graph, currentTimeRange, dataSets).filter(
    (value) => !isNaN(value)
  );

  // Things don't look good fitting the graph to 1 data point if we have zoomed/panned far in. In this case,
  // keep the fit at the previous value of the dataRange
  if (valuesWithinTimeRange.length < 2) {
    return currentDataRange;
  }

  let dataMin = Math.min(...valuesWithinTimeRange) - Y_BUFFER_AMOUNT;
  let dataMax = Math.max(...valuesWithinTimeRange) + Y_BUFFER_AMOUNT;
  // If our buffers or other artifacts have caused our ranges to escape the provided limits, clamp them.
  if (dataMin < minLimit) {
    dataMin = minLimit;
  }
  if (maxLimit < dataMax) {
    dataMax = maxLimit;
  }
  // Rounding to hundredths so we dont end up with unnecessary precision anywhere.
  return [dataMin, dataMax].map((v) => Math.round(v * 100) / 100) as [number, number];
}

// Check if a date falls inside a range. For YoY contexts, we want to transpose the date year to the specified range.
// e.g. The range Oct 15, 1999 - Mar 15, 2000 contains the date Jan 15, 2011
// because Jan 15 falls between Oct 15 and Mar 15 for the 2011 year.
// Note: YoY graph time ranges will use 2000 as a starting value. This value is arbitrary and usually before
// most time series. Since USGS water years are offset from calendar years, the 2000 water year begins in 1999 in this range.
function rangeIncludesDateYoY([start, end]: [Date, Date], d: Date): boolean {
  // Approach: Transpose the date we're checking into each year within our range, and see if it falls inside the range.
  // For the example, transoposing Jan 15 to 1999 is the wrong year, and 2000 is the right year, because Jan 15 2000 is in our range.
  // Similarly, transposing Dec 15 2011 to 1999 is the right year, and 2000 the wrong year, because Dec 15 1999 is in our range.
  const transposeToYear = (d, year) => {
    const transposed = new Date(d);
    transposed.setFullYear(year);
    return transposed;
  };
  const transposedToStart = transposeToYear(d, start.getFullYear());
  const transposedToEnd = transposeToYear(d, end.getFullYear());

  return [transposedToStart, transposedToEnd].some((d) => start <= d && d <= end);
}

function getValuesWithinTimeRange(
  graph: Graph | GraphWithTimeRange,
  dateRange: GraphTimeRange,
  dataSets?: GraphColumn[] | null
): number[] {
  const [start, end] = dateRange;
  if (graph.graphRange.type !== 'by-year') {
    return graph.dataPoints.filter(([d]) => start <= d && d <= end).map(([, value]) => value);
  } else {
    if (dataSets) {
      /**
       * Filter the data points which are disabled, through the legend.
       */
      const yearType = (graph.graphRange as ByYearGraphRange).yearType || 'calendar';
      const enabledYears: number[] = dataSets
        .filter((s) => s.isSelected)
        .map(({name}): number => parseInt(name));
      // Filter data down to years enabled through the legend.
      // The inner ternary determines whether we select points based on calendar year or water year.
      const dataForEnabledYears: GraphPoint[] = graph.dataPoints.filter(([d]) =>
        enabledYears.includes(yearType === 'calendar' ? d.getFullYear() : getWaterYear(d))
      );
      /**
       * YoY, so we want to fetch the same date ranges from each year. The provided
       * timeRange may span a part of one year, so we need to transpose each data point
       * into the range's year and filter things outside of that range.
       */
      const [adjustedMin, adjustedMax] = clampYoYRange(start, end);
      return dataForEnabledYears
        .filter(([d]) => rangeIncludesDateYoY([adjustedMin, adjustedMax], d))
        .map(([, value]) => value);
    }
    /**
     * This is a weird case and probably a programmer error, but lets fail loudly instead of forcing
     * devs to figure out where the error that makes their chart empty might originate.
     */
    throw Error('YoY graph type provided, but no data sets available.');
  }
}

// We need to clamp our time ranges because otherwise buffering makes calendar year date math
// harder by forcing us to deal with edge cases around year roll-overs. This isn't an issue for
// smaller ranges within a year or those spanning subsequent years.
// Buffering is something we do in ByYearChart, and needs to happen so that values at the very
// start and end are fully visible.
function clampYoYRange(start: Date, end: Date): [Date, Date] {
  const startYear = start.getFullYear();
  const endYear = end.getFullYear();
  const yearDifference = endYear - startYear;

  if (yearDifference > 1) {
    return [new Date(startYear + 1, 0, 1, 1, 1, 1), new Date(endYear - 1, 12, 0, 24, 0, -1)];
  } else {
    return [start, end];
  }
}

/**
 * Zoom all time scales (i.e., x-axes) to a specified range. Important for
 * charts with multiple datasets to keep axes and lines in sync.
 */
export function zoomTimeScales(chart: Chart, timeRange: GraphTimeRange): void {
  const range = {min: timeRange[0].getTime(), max: timeRange[1].getTime()};
  Object.keys(chart.scales).forEach((id) => {
    if (chart.scales[id].type === 'time') {
      chart.zoomScale(id, range);
    }
  });
}

/**
 * Zoom all time scales (i.e., x-axes) to a specified range. Important for
 * charts with multiple datasets to keep axes and lines in sync.
 */
export function zoomDataScales(chart: Chart, dataRange: NoteGraphDataRange): void {
  if (!dataRange) {
    return;
  }
  const range = {min: dataRange[0], max: dataRange[1]};
  Object.keys(chart.scales).forEach((id) => {
    if (chart.scales[id].type === 'linear') {
      chart.zoomScale(id, range);
    }
  });
}
/**
 * A function to convert a year to a column, ensuring that we perform the
 * correct transformation each time.
 */

export function convertYearToColumnName(year: number) {
  return year.toString();
}

/**
 * A function to calibrate a date to display in a year-over-year chart with a
 * normalized x-axis. Date and year are passed as two separate props since we
 * sometimes group data points in a different year (e.g., buffering a line with
 * a point from preceding and succeeding years so lines extend off the chart).
 */
export function calibrateDate({
  date,
  year = date.getFullYear(),
  xAxisYear = YOY_X_AXIS_YEAR,
}: {
  date: Date;
  year?: number;
  xAxisYear?: number;
}) {
  return moment(date)
    .subtract(year - xAxisYear, 'years')
    .startOf('day')
    .toDate();
}

/**
 * A function that parses all of the ISO string dates on the API note graph
 * object as Date objects for compatibility with components.
 */
export function parseGraphIsoStringsAsDates(graph: NoteGraph): GraphWithTimeRange {
  return {
    ...graph,
    dataPoints: graph.dataPoints.map((p) => [new Date(p[0]), ...p.slice(1)]) as GraphPoint[],
    graphTimeRange: graph.graphTimeRange.map((s) => new Date(s)) as GraphTimeRange,
    graphDataRange: graph.graphDataRange,
    graphRange:
      graph.graphRange.type === 'custom'
        ? ({
            ...graph.graphRange,
            range: graph.graphRange.range.map((s) => (s ? new Date(s) : null)),
          } as CustomGraphRange)
        : graph.graphRange,
  };
}

/**
 * A function that converts all graph Date objects to ISO string dates for
 * compatibility with API payload types.
 */
export function parseGraphDatesAsIsoStrings(graph: GraphWithTimeRange): NoteGraph {
  return {
    ...graph,
    dataPoints: graph.dataPoints.map((p) => [
      p[0].toISOString(),
      ...p.slice(1),
    ]) as NoteGraphPoint[],
    graphTimeRange: parseGraphTimeRangeAsIsoStrings(graph.graphTimeRange),
    graphRange:
      graph.graphRange.type === 'custom'
        ? parseCustomGraphRangeAsIsoStrings(graph.graphRange)
        : graph.graphRange,
  };
}

/**
 * A function that converts the graph time range Date objects to ISO strings.
 */
export function parseGraphTimeRangeAsIsoStrings(graphTimeRange: GraphTimeRange) {
  return graphTimeRange.map((d) => d.toISOString()) as NoteGraphTimeRange;
}

/**
 * A function that converts the graph time range Date objects to ISO strings.
 */
export function parseCustomGraphRangeAsIsoStrings(graphRange: CustomGraphRange) {
  return {
    ...graphRange,
    range: graphRange.range.map((d) => (d ? d.toISOString() : '')),
  } as CustomNoteGraphRange;
}
