import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import {WithMonitoringFeatures} from 'app/pages/ProjectDashboardSwitch/view';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {useProjects} from 'app/providers/ProjectsProvider';
import * as C from 'app/src/utils/constants';
import {getFeatureCollectionsByType} from 'app/utils/featureCollectionUtils';
import {atLeastLensTier, useUserInfo} from 'app/utils/featureFlags';

import cs from './Dashboard.styl';
import AppNav from '../AppNav';
import {NavSidebarContainer} from '../NavSidebar';
import {MultiFeatureAnalysisWidget} from './MultiFeatureAnalysisWidget/MultiFeatureAnalysisWidget';

type DashboardPageProps = {
  loggedInUserActions: LoggedInUserActions;
  history: History;
};

const DEMO_PROJECT_ID = '1578232f-7383-4694-9916-50fa478d8bf5';

const Dashboard: React.FC<DashboardPageProps> = ({history, loggedInUserActions}) => {
  const [organization, profile] = useUserInfo();
  const orgName = organization.get('name');
  const isEnterprise =
    atLeastLensTier(C.LENS_TIER_ENTERPRISE, organization) ||
    organization.get('id') === C.INTERNAL_ORGANIZATION_ID;

  // TODO(arohan): This is tying features that we load to a specific project, which we don't want
  // for now, it's here so we can build using real feature data, but we need to port this to work
  // efficiently with a list of feature IDs.
  // We should be able to use the /search features endpoint for this, and/or write an endpoint
  // where we just pass back a list of featureIds and get features.
  const [projects, featureCollections, projectsActions] = useProjects();

  // Using a specific project for now
  const targetProject = projects?.find((p) => p!.get('id') === DEMO_PROJECT_ID) || null;

  const [primaryFeatureCollection] = targetProject
    ? getFeatureCollectionsByType(targetProject, featureCollections)
    : [null];

  // Placeholder
  const selectedFeatureIdParams = I.Set<string>();

  const refreshFeatureCollection = (featureCollectionId: number) => {
    if (projectsActions) {
      return projectsActions.refreshFeatureCollection(featureCollectionId);
    }
  };

  return (
    <>
      <AppNav
        history={history}
        organization={organization}
        profile={profile}
        selectedProject={null}
        loggedInUserActions={loggedInUserActions}
      />
      <NavSidebarContainer history={history}>
        <div className={cs.dashboardContainer} data-testid="dashboard-container">
          <h1>
            Placeholder {!isEnterprise ? 'preview' : ''} dashboard for {orgName}
          </h1>

          {targetProject && primaryFeatureCollection && (
            <WithMonitoringFeatures
              selectedProject={targetProject}
              selectedFeatureCollection={primaryFeatureCollection}
              selectedFeatureIdParams={selectedFeatureIdParams}
              refreshFeatureCollection={refreshFeatureCollection}
            >
              {({features}) => (
                <>
                  <h2>Target Project: {targetProject.get('name')}</h2>
                  <MultiFeatureAnalysisWidget features={features} />
                </>
              )}
            </WithMonitoringFeatures>
          )}
        </div>
      </NavSidebarContainer>
    </>
  );
};

export default Dashboard;
