import {
  ActiveElement,
  Chart,
  ChartData,
  ChartEvent,
  LegendItem,
  PluginOptionsByType,
  ScaleChartOptions,
  ScaleOptionsByType,
} from 'chart.js';
import 'chartjs-adapter-moment';
import {AnnotationOptions, AnnotationPluginOptions} from 'chartjs-plugin-annotation';
import autocolorPlugin from 'chartjs-plugin-autocolors';
import chroma from 'chroma-js';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import React, {CSSProperties} from 'react';

import {
  ByYearGraphRange,
  DataRange,
  Graph,
  GraphByUnit,
  GraphColumn,
  GraphMetadata,
  GraphTimeRange,
  GraphWithTimeRange,
} from 'app/components/AnalyzePolygonChart/types';
import {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import COLORS from 'app/styles/colors.json';
import * as colorUtils from 'app/utils/colorUtils';
import {flexibleParseInt, getWaterYear} from 'app/utils/conversionUtils';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import {
  BORDER_COLOR,
  CURSOR_WIDTH_ACTIVE,
  CURSOR_WIDTH_INACTIVE,
  DataPoint,
  TOOLTIP_TIME_FORMAT,
  X_SCALE_ID,
  getCumulativeRange,
  isYearSelected,
  makeTooltipLabelCallback,
  makeXAxisID,
  useHtmlLegend,
  useLineChart,
  useYAxisTooltip,
  yearsToLineData,
} from './utils';

// RDM constants
export const CURRENT_YEAR = COLORS.brightBlue;
export const UNKNOWN_COLOR = COLORS.darkGray;
export const IN_COMPLIANCE_COLOR = '#5e8827';
export const OUT_OF_COMPLIANCE_COLOR = '#eead0c';

// A leap year in the past to calibrate all data points to so that they may be
// shown on the same axis.
export const YOY_X_AXIS_YEAR = 2000;
const CALENDAR_YEAR_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const USGS_WATER_YEAR_MONTHS = [
  'Oct',
  'Nov',
  'Dec',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
];

// USGS Water year is defined as the 12 month period beginning on October 1, and going through September 30 of the next year.
// A water year ending in 2000 is said to be the 2000 water year. Source: https://water.usgs.gov/nwc/explain_data.html
const USGS_WATER_YEAR_START = `10/01/${YOY_X_AXIS_YEAR - 1}`;
const USGS_WATER_YEAR_END = `09/30/${YOY_X_AXIS_YEAR}`;

/** Buffer the time range limits on either end by 7 days so data points and
 * annotations at the edges of the time range are fully visible. */
const CALENDAR_YEAR_TIME_RANGE_LIMITS: GraphTimeRange = [
  moment(`01/01/${YOY_X_AXIS_YEAR}`).startOf('day').subtract(7, 'days').toDate(),
  moment(`12/31/${YOY_X_AXIS_YEAR}`).endOf('day').add(7, 'days').toDate(),
];

const USGS_WATER_YEAR_TIME_RANGE_LIMITS: GraphTimeRange = [
  moment(USGS_WATER_YEAR_START).startOf('day').subtract(7, 'days').toDate(),
  moment(USGS_WATER_YEAR_END).endOf('day').add(7, 'days').toDate(),
];

const getAxisYear = (yearType: 'calendar' | 'usgs-water', date: moment.Moment): number => {
  switch (yearType) {
    // Because of our default range limits, above, we want to place each axis somewhere on this year, which get translated for each subsequent year over year.
    case 'calendar':
      return YOY_X_AXIS_YEAR;
    // USGS Water year is set back a few months. The ranges above define
    case 'usgs-water':
      return date.toDate().getMonth() >= 9 ? YOY_X_AXIS_YEAR - 1 : YOY_X_AXIS_YEAR;
  }
};

export interface ByYearChartProps {
  graph: Graph | GraphWithTimeRange;
  selectedColumns: GraphColumn[] | null;
  showCumulativeValues?: boolean;
  graphMetadata?: GraphMetadata | null;
  imageRefs: mapUtils.MapImageRefs;
  areaInM2: number;
  areaUnit: ApiOrganizationAreaUnit;
  onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => void;
  onClickLegend: (chart: Chart) => void;
  onInitialize: (chart: Chart) => void;
  onPanComplete: (chart: Chart) => void;
  onZoomComplete: (chart: Chart) => void;
  setChartDataRange?: (range: DataRange) => void;
  getDataColumn: (datasetName: string | number) => GraphColumn | undefined;
  devicePixelRatio?: number;
  disablePanZoom?: boolean;
  hideLegend?: boolean;
  isLegendInteractive?: boolean;
  hideTooltip?: boolean;
  showTitle?: boolean;
  graphStyle?: CSSProperties;
  isExpanded: boolean;
  children: (chart: Chart) => React.ReactNode;
  dataRange: DataRange;
}

/**
 * Component to render a line chart with a dataset for each year and an x-axis
 * ranging from Jan 1 to Dec 31.
 *
 * Note: the Chart.js types are a bit lacking (e.g., optional props erroneously
 * typed as required) so we have to do a bit of casting.
 */
const ByYearChart: React.FunctionComponent<ByYearChartProps> = ({
  graph,
  selectedColumns,
  showCumulativeValues,
  graphMetadata,
  imageRefs,
  areaInM2,
  areaUnit,
  onClick,
  onClickLegend,
  onInitialize,
  onPanComplete,
  onZoomComplete,
  setChartDataRange,
  getDataColumn,
  devicePixelRatio,
  disablePanZoom,
  hideLegend,
  isLegendInteractive = true,
  hideTooltip,
  showTitle,
  graphStyle,
  isExpanded,
  children,
  dataRange,
}) => {
  // Our graph range should be ByYearGraphRange if we're rendering this component, which
  // means we should check for year type. Default to calendar year if none is provided.
  // Defaults are provided by our AA default ranges, but old notes may not include it.
  const yearType = (graph.graphRange as ByYearGraphRange).yearType || 'calendar';
  const TIME_RANGE_LIMITS =
    yearType === 'calendar' ? CALENDAR_YEAR_TIME_RANGE_LIMITS : USGS_WATER_YEAR_TIME_RANGE_LIMITS;
  const MONTHS = yearType === 'calendar' ? CALENDAR_YEAR_MONTHS : USGS_WATER_YEAR_MONTHS;
  const selectedByColumn: {[column: string]: boolean} = React.useMemo(
    () => selectedColumns?.reduce((o, col) => ({...o, [col.name]: col.isSelected}), {}) ?? {},
    [selectedColumns]
  );
  const [dataPointsByYear, dataPoints] = React.useMemo<[GraphByUnit, DataPoint[]]>(() => {
    const dataPoints = graph.dataPoints.map(([date, value, meta]) => {
      const adjustedDate = layerUtils.conditionallyAdjustLayerDate(date, graph.layerKey);
      return {
        x: adjustedDate.toDate().setFullYear(getAxisYear(yearType, adjustedDate)),
        y: value,
        date: adjustedDate.toDate(),
        cursor: meta.cursor,
      };
    });
    let byYear = {};
    switch (yearType) {
      case 'calendar':
        byYear = groupBy(dataPoints, ({date}) => moment(date).year());
        break;
      case 'usgs-water':
        byYear = groupBy(dataPoints, ({date}) => getWaterYear(date));
    }
    return [byYear, dataPoints];
  }, [graph.dataPoints, graph.layerKey, yearType]);

  /** Calculate x-axis values. */
  const {years, initialTimeRange} = React.useMemo(() => {
    const years = Object.keys(dataPointsByYear).reverse();
    /** Get initial time range from graph prop, if it exists; this should only
     * be the case for graphs saved on notes. */
    const initialTimeRange = graph.graphTimeRange ?? TIME_RANGE_LIMITS;
    return {years, initialTimeRange};
  }, [TIME_RANGE_LIMITS, dataPointsByYear, graph.graphTimeRange]);

  // Generate cumulative values by year in case we need them.
  // TODO(anthony): It'd be good to hoist these calculations way up.
  const cumulativeByYear: GraphByUnit = React.useMemo(
    () =>
      years.reduce((cumulative, year) => {
        const dataForYear = dataPointsByYear[year];
        let accumulatedValue = 0;
        const yearAsAccumulatingValues = dataForYear.map((point) => ({
          ...point,
          y: (accumulatedValue += point.y),
        }));
        return {...cumulative, [year]: yearAsAccumulatingValues};
      }, {}),
    [dataPointsByYear, years]
  );

  // Our cumulative values will have a different range than the normal yoy graph.
  const cumulativeRange = React.useMemo(
    () => getCumulativeRange(cumulativeByYear),
    [cumulativeByYear]
  );

  /** Calulate trendline, cumulative for enabled years. */
  const trendData = React.useMemo(() => {
    const yearSelection = years.slice().filter((y) => selectedByColumn[y]);
    // If we're using cumulative values, use the yearly cumulative values instead of the unmodified data
    const data: DataPoint[] = showCumulativeValues
      ? Object.values(cumulativeByYear).flat()
      : dataPoints;
    // Its easiest to generate a monthly trend line from data grouped by month
    const dataPointsByMonth = groupBy(data, ({date}) => moment(date).format('MMM'));
    const monthsWithData = Object.keys(dataPointsByMonth);
    // Ensures we only index months that have data points
    const months = MONTHS.filter((m) => monthsWithData.includes(m));

    return months
      .map((month) => {
        let datasetForMonth = dataPointsByMonth[month];
        // Filter if selected years are 'some'. If years are 'all' do not filter.
        if (yearSelection.length < years.length && yearSelection.length != 0) {
          /**
           * We need to be sure to get water year here if we're using it, otherwise the trend line
           * will truncate to calendar year boundaries.
           */
          const isCalendarYear = yearType === 'calendar';
          datasetForMonth = datasetForMonth.filter(({date}) =>
            yearSelection.includes(
              isCalendarYear ? date.getFullYear().toString() : getWaterYear(date).toString()
            )
          );
        }
        const average =
          datasetForMonth.reduce((sum, {y: value}) => sum + value, 0) / datasetForMonth.length;
        // Generate a point in the middle of the month for our trend data.
        const xValue = moment().year(YOY_X_AXIS_YEAR).month(month).date(15);
        // If average is not a number, don't create a data point.
        if (Number.isNaN(average)) {
          return null;
        }
        // Generate a series from calculations. Conditionally adjust year offset for usgs water year.
        const x = xValue.toDate().setFullYear(getAxisYear(yearType, xValue));
        const date = xValue.toDate();
        // Returns two points, one for each series type: average and cumulative
        return {
          x,
          y: average,
          date,
          cursor: '',
        };
      })
      .filter((m) => m);
  }, [
    MONTHS,
    cumulativeByYear,
    dataPoints,
    selectedByColumn,
    showCumulativeValues,
    yearType,
    years,
  ]);

  /** Get column (year) color. Preferentially uses the initial column color from
   * the graph prop, if it exists; this should only be the case for graphs saved
   * on notes.
   *
   * For TNC and the RDM use-case, this function considers supplemental compliance data, organized by year.
   * */
  const getColor = React.useCallback(
    (year: number | string) => {
      let color = getDataColumn(year)?.color;
      // If the data column already has a color, such as for saved graphs, use that.
      if (!color) {
        // If compliance data is present provide compliance colors, else regular yoy colors.
        if (graphMetadata?.complianceByYear) {
          const complianceValue: boolean | undefined = graphMetadata.complianceByYear[year];
          switch (true) {
            case new Date().getFullYear() === flexibleParseInt(year):
              color = CURRENT_YEAR;
              break;
            case complianceValue:
              color = IN_COMPLIANCE_COLOR;
              break;
            case complianceValue === false:
              color = OUT_OF_COMPLIANCE_COLOR;
              break;
            default:
              color = UNKNOWN_COLOR;
          }
        }
      }
      return color;
    },
    [getDataColumn, graphMetadata]
  );

  /** Create a dataset for each year containing a data point. */
  const datasets = React.useMemo(() => {
    let lineData: ChartData<'line'>['datasets'] & {pointRadius?: number} = [];
    const yearSelection = Object.fromEntries(
      years.map((year) => [year, isYearSelected(year, selectedByColumn, getDataColumn)])
    );
    if (showCumulativeValues) {
      lineData = yearsToLineData(years, cumulativeByYear, yearSelection, hideTooltip, getColor);
    } else {
      lineData = yearsToLineData(years, dataPointsByYear, yearSelection, hideTooltip, getColor);
    }

    // Same method as above except using the column name for the trendline
    const trendColumnName = 'Average';
    const isAverageYearSelected = isYearSelected(trendColumnName, selectedByColumn, getDataColumn);
    // TODO(anthony): figure out why i have to erase trend values types, and not dataPointsByYear
    lineData.push({
      xAxisID: makeXAxisID('trend'),
      data: trendData as unknown as DataPoint[],
      label: trendColumnName,
      hidden: !isAverageYearSelected,
      backgroundColor: '#B00',
      pointRadius: -1, // prevents point from being hoverable
      pointHoverRadius: 0,
      borderColor: '#B00',
      borderWidth: 2,
    });

    return lineData;
  }, [
    years,
    showCumulativeValues,
    selectedByColumn,
    getDataColumn,
    cumulativeByYear,
    hideTooltip,
    getColor,
    dataPointsByYear,
    trendData,
  ]);

  /** Display a vertical line for each image reference with a valid cursor. */
  const annotationPluginOptions = React.useMemo<AnnotationPluginOptions>(
    () => ({
      drawTime: 'beforeDraw',
      annotations: imageRefs.reduce(
        (annotations, imageRef, index) => {
          const imageRefCursor = imageRef.cursor;
          if (imageRefCursor === null) {
            return annotations;
          }

          const imageRefDate = layerUtils.conditionallyAdjustLayerDate(
            new Date(imageRefCursor),
            imageRef.layerKey
          );
          const imageRefYear = imageRefDate.year();
          const imageRefYearStr = imageRefYear.toString();
          const isSameLayer = graph.layerKey === imageRef.layerKey;

          return {
            ...annotations,
            [`imageRef-${index}`]: {
              type: 'line',
              scaleID: X_SCALE_ID,
              value: imageRefDate.toDate().setFullYear(YOY_X_AXIS_YEAR),
              display: (context) => {
                const legendItems = context.chart.legend?.legendItems;
                const legendItem = legendItems?.find(({text}) => text === imageRefYearStr);
                return legendItem?.hidden === false;
              },
              borderColor: ({chart}) => {
                /** We want to pull in the latest dataset color so the image ref
                 * annotation has the same opacity as the year it falls in. The
                 * dataset’s backgroundColor type is different than what
                 * borderColor expects, but we know that we’ve set this to a hex
                 * string, so we can cast to a string here. */
                const dataset = chart.data.datasets.find(({label}) => label === imageRefYearStr);
                const datasetColor = dataset?.backgroundColor;
                return isSameLayer && datasetColor ? (datasetColor as string) : BORDER_COLOR;
              },
              borderWidth: isSameLayer ? CURSOR_WIDTH_ACTIVE : CURSOR_WIDTH_INACTIVE,
              borderDash: [4, 2],
            },
          };
        },
        {} as Record<string, AnnotationOptions<'line'>>
      ),
    }),
    [graph.layerKey, imageRefs]
  );

  const legendPluginOptions = React.useMemo<PluginOptionsByType<'line'>['legend']>(
    () =>
      ({
        display: false,
        labels: {
          generateLabels: (chart) => {
            const isAnyDatasetVisible = chart.getVisibleDatasetCount() > 0;
            return [
              /** Invoke the original generate labels callback to create a legend
               * item for each year, then append a special item to bulk toggle the
               * visiblity of all years. */
              ...Chart.defaults.plugins.legend.labels.generateLabels(chart),
              // Only show the none/all switch if the legend is interactive
              isLegendInteractive
                ? {
                    datasetIndex: null,
                    text: isAnyDatasetVisible ? 'None' : 'All',
                    fillStyle: isAnyDatasetVisible ? '#ffffff' : colorUtils.default.darkestGray,
                  }
                : {},
            ];
          },
        },
      }) as PluginOptionsByType<'line'>['legend'],
    [isLegendInteractive]
  );

  /** Optionally display a tooltip on point hover. */
  const tooltipPluginOptions = React.useMemo<PluginOptionsByType<'line'>['tooltip']>(
    () =>
      ({
        enabled: !hideTooltip,
        displayColors: false,
        callbacks: {
          title: (tooltipItems) => {
            const tooltipItem = tooltipItems[0];
            const datum = tooltipItem.dataset.data[tooltipItem.dataIndex] as DataPoint;
            return moment(datum.date).format(TOOLTIP_TIME_FORMAT);
          },
          label: makeTooltipLabelCallback(graph, areaInM2, areaUnit),
        },
      }) as PluginOptionsByType<'line'>['tooltip'],
    [areaInM2, areaUnit, graph, hideTooltip]
  );

  /** Allow chart to be optionally panned and zoomed. */
  const zoomPluginOptions = React.useMemo<PluginOptionsByType<'line'>['zoom']>(
    () => ({
      limits: {
        x: {
          min: TIME_RANGE_LIMITS[0].getTime(),
          max: TIME_RANGE_LIMITS[1].getTime(),
          minRange: 15 * 24 * 60 * 60 * 1000,
        },
      },
      pan: {
        mode: 'x',
        /** HACK: The chartjs-plugin-zoom plugin does not respond to changes in
         * the pan.enabled option after initialization, but it does respond to
         * changes in pan.onPanStart. */
        enabled: true,
        onPanStart: disablePanZoom ? () => false : undefined,
        onPanComplete: ({chart}) => onPanComplete(chart),
      },
      zoom: {
        mode: 'x',
        pinch: {
          enabled: !disablePanZoom,
        },
        wheel: {
          enabled: !disablePanZoom,
        },
        onZoomComplete: ({chart}) => onZoomComplete(chart),
      },
    }),
    [TIME_RANGE_LIMITS, disablePanZoom, onPanComplete, onZoomComplete]
  );

  /** Configure time scale (i.e., x-axis) settings. */
  const xScaleOptions = React.useMemo<ScaleOptionsByType<'time'>>(
    () =>
      ({
        type: 'time',
        min: initialTimeRange[0].getTime(),
        max: initialTimeRange[1].getTime(),
        time: {
          tooltipFormat: TOOLTIP_TIME_FORMAT,
          unit: 'month',
          displayFormats: {month: 'MMM'},
        },
        ticks: {
          autoSkip: true,
          autoSkipPadding: 10,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          borderDash: [1, 1],
        },
      }) as unknown as ScaleOptionsByType<'time'>,
    [initialTimeRange]
  );

  /// Check to see if we should use cumulativeRange or the passed in dataRange
  const adjustedDataRange = React.useMemo(
    () => (showCumulativeValues ? cumulativeRange : dataRange),
    [showCumulativeValues, cumulativeRange, dataRange]
  );

  // If the adjustedDataRange has been changed, communicate that to parents.
  React.useEffect(() => {
    if (setChartDataRange) {
      setChartDataRange(adjustedDataRange);
    }
  }, [setChartDataRange, adjustedDataRange]);

  /** Configure linear scale (i.e., y-axis) settings. */
  const yScaleOptions = React.useMemo<ScaleOptionsByType<'linear'>>(
    () =>
      ({
        type: 'linear',
        min: adjustedDataRange[0],
        max: adjustedDataRange[1],
        ticks: {
          precision: 1,
          maxTicksLimit: 2,
        },
        grid: {
          borderDash: [1, 1],
          drawBorder: false,
        },
      }) as ScaleOptionsByType<'linear'>,
    [adjustedDataRange]
  );

  /** Configure additional time scale settings for each per-year dataset.
   * Required to render the dataset lines propertly but hidden because we only
   * want to display the main x-axis. */
  const extraScaleOptions = React.useMemo<ScaleChartOptions['scales']>(
    () => ({
      [makeXAxisID('trend')]: {
        type: 'time',
        display: false,
        min: initialTimeRange[0].getTime(),
        max: initialTimeRange[1].getTime(),
      },
      [makeXAxisID('cumulative')]: {
        type: 'time',
        display: false,
        min: initialTimeRange[0].getTime(),
        max: initialTimeRange[1].getTime(),
      },
      ...years.reduce(
        (accum, year) => ({
          ...accum,
          [makeXAxisID(year)]: {
            type: 'time',
            display: false,
            min: initialTimeRange[0].getTime(),
            max: initialTimeRange[1].getTime(),
            time: {tooltipFormat: TOOLTIP_TIME_FORMAT},
          },
        }),
        {}
      ),
    }),
    [initialTimeRange, years]
  );

  const titlePluginOptions = React.useMemo<Partial<PluginOptionsByType<'line'>['title']>>(
    () => ({
      display: !!showTitle,
      text: graph.layerDisplay,
      font: {weight: 'normal'} as PluginOptionsByType<'line'>['title']['font'],
    }),
    [showTitle, graph.layerDisplay]
  );

  const {renderAxisTooltip, hoverYAxisPlugin} = useYAxisTooltip(graph);
  const {legendItems, htmlLegendPlugin, renderLegend} = useHtmlLegend();

  const {chart, canvasRefCallback} = useLineChart({
    datasets,
    labels: TIME_RANGE_LIMITS,
    annotationPluginOptions,
    legendPluginOptions,
    tooltipPluginOptions,
    zoomPluginOptions,
    titlePluginOptions,
    xScaleOptions,
    yScaleOptions,
    extraScaleOptions,
    plugins: [htmlLegendPlugin, hoverYAxisPlugin, autocolorPlugin],
    onClick,
    onInitialize,
    devicePixelRatio,
    isExpanded,
  });

  const handleLegendClick = React.useCallback(
    (clickedLegendItem: LegendItem, legendItems: LegendItem[] | null | undefined) => {
      if (chart) {
        /** If the clicked legend item has a null dataset index, we can assume
         * for now that it’s the bulk operation legend item and toggle all
         * dataset visibilities. Otherwise, we only toggle the visibility of
         * the dataset for the clicked legend item. */
        if (clickedLegendItem.datasetIndex === null) {
          const isEveryDatasetHidden = chart.getVisibleDatasetCount() === 0;
          legendItems?.forEach((legendItem) => {
            chart.getDatasetMeta(legendItem.datasetIndex).hidden = !isEveryDatasetHidden;
          });
        } else {
          const isDatasetHidden = clickedLegendItem.hidden;
          chart.getDatasetMeta(clickedLegendItem.datasetIndex).hidden = !isDatasetHidden;
        }
        resetDatasetColors(chart);
        chart.update();
        onClickLegend(chart);
      }
    },
    [onClickLegend, chart]
  );

  const handleLegendMouseover = React.useCallback(
    (datasetIndex) => {
      if (chart) {
        chart.canvas.style.cursor = 'pointer';
        modifyDatasetColors(chart, datasetIndex);
        chart.update();
      }
    },
    [chart]
  );

  const handleLegendMouseout = React.useCallback(() => {
    if (chart) {
      chart.canvas.style.cursor = 'unset';
      resetDatasetColors(chart);
      chart.update();
    }
  }, [chart]);

  const legend = React.useMemo(
    () =>
      renderLegend(
        legendItems,
        isLegendInteractive,
        isExpanded,
        handleLegendClick,
        handleLegendMouseover,
        handleLegendMouseout
      ),
    [
      legendItems,
      isLegendInteractive,
      isExpanded,
      handleLegendClick,
      handleLegendMouseover,
      handleLegendMouseout,
      renderLegend,
    ]
  );

  return (
    <React.Fragment>
      <div style={graphStyle}>
        <canvas ref={canvasRefCallback} />
      </div>
      {!!chart && children(chart)}
      {!hideLegend && legend}
      {renderAxisTooltip()}
    </React.Fragment>
  );
};

export default ByYearChart;

/**
 * All of the properties on the dataset object that control color.
 */
const DATASET_COLOR_PROPERTIES = [
  'backgroundColor',
  'pointBackgroundColor',
  'pointBorderColor',
  'borderColor',
] as const;

/**
 * Highlight dataset at provided dataset index by decreasing all other dataset
 * color transparency values to 10%.
 *
 * Chart.js unfortunately does not expose opacity properties, so we must modify
 * each color directly. We do that by creating a new hex value with the provided
 * alpha value. Using chroma-js helps maintain resiliency against hex values of
 * different lengths.
 */
function modifyDatasetColors(chart: Chart, datasetIndex: number | null) {
  chart.data.datasets.forEach((dataset, index) => {
    if (datasetIndex !== null && datasetIndex !== index) {
      DATASET_COLOR_PROPERTIES.forEach((property) => {
        const color = dataset[property] as string;
        // If a dataset doesnt specify these colors, do nothing.
        if (!color) {
          return;
        }
        dataset[property] = chroma(color).alpha(0.1).hex();
      });
    }
  });
}

/**
 * Reset all dataset color transparency values to 100%.
 *
 * Chart.js unfortunately does not expose opacity properties, so we must modify
 * each color directly. We do that by creating a new hex value with the provided
 * alpha value. Using chroma-js helps maintain resiliency against hex values of
 * different lengths.
 */
function resetDatasetColors(chart: Chart) {
  chart.data.datasets.forEach((dataset) => {
    DATASET_COLOR_PROPERTIES.forEach((property) => {
      const color = dataset[property] as string;
      // If a dataset doesnt specify these colors, do nothing.
      if (!color) {
        return;
      }
      dataset[property] = chroma(color).alpha(1).hex();
    });
  });
}
