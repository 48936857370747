import {UseQueryResult, useQuery} from '@tanstack/react-query';

import {GraphTimeRange} from 'app/components/AnalyzePolygonChart/types';

import {MOCK_RESPONSE} from '../mockData';

interface FetchFeatureStatsApiParams {
  featureIds: string[];
  startDate: string; // ISO string
  endDate: string; // ISO string
  statName: 'mean' | 'sum' | 'median' | 'max' | 'min' | string; // Allow string for flexibility
  sourceId: string; // Corresponds to layer.key
  timeAggregation: 'monthly' | 'yearly' | string; // Allow string
}

// Example: { "feature1": { "2023-01-01T00:00:00Z": 10.5, "2023-02-01T00:00:00Z": 11.2 }, ... }
export type FeatureStatsApiResponse = Record<string, Record<string, number>>;

// (Replace with actual implementation)
async function fetchFeatureStatsFromApi(
  params: FetchFeatureStatsApiParams
): Promise<FeatureStatsApiResponse> {
  console.info('fetchFeatureStatsFromApi', params);
  return MOCK_RESPONSE;
}

//TODO: why do we need both this and FetchFeatureStatsApiParams? consolidate?
interface UseAggregatedFeatureStatsParams {
  featureIds: string[];
  layerKey: string;
  timeRange: GraphTimeRange;
  statName: FetchFeatureStatsApiParams['statName'];
  timeAggregation: FetchFeatureStatsApiParams['timeAggregation'];
  // How to aggregate the results from the API on the frontend
  frontendAggregation?: 'sum' | 'mean';
}

export function useMultiFeatureAnalysisStats({
  featureIds,
  layerKey,
  timeRange,
  statName = 'mean',
  timeAggregation = 'monthly',
  frontendAggregation = 'mean', // Default to summing the results
}: UseAggregatedFeatureStatsParams): UseQueryResult<FeatureStatsApiResponse, Error> {
  // Stable query key including all relevant API parameters
  const queryKey = [
    'aggregatedFeatureStats', // New key base reflects the hook's purpose
    featureIds?.sort().join('-') || 'no-features',
    layerKey,
    timeRange?.[0]?.toISOString(),
    timeRange?.[1]?.toISOString(),
    statName,
    timeAggregation,
    frontendAggregation, // Include frontend aggregation method
  ];

  return useQuery({
    queryKey,
    queryFn: async (): Promise<FeatureStatsApiResponse> => {
      try {
        const apiParams: FetchFeatureStatsApiParams = {
          featureIds,
          startDate: timeRange[0].toISOString(),
          endDate: timeRange[1].toISOString(),
          statName,
          sourceId: layerKey,
          timeAggregation,
        };
        const apiResponse = await fetchFeatureStatsFromApi(apiParams);

        return apiResponse;
      } catch (error) {
        console.error('Error during aggregated stats processing:', error);
        if (error instanceof Error) {
          throw error;
        } else {
          throw new Error(String(error || 'Unknown processing error'));
        }
      }
    },
  });
}
