import 'chartjs-adapter-moment';
import * as B from '@blueprintjs/core';
import React from 'react';

import {ApiFeature} from 'app/modules/Remote/Feature';

/**
 * Component to render a table of feature rows, as well the setters to affect a chart that
 * takes each row as a dataset.
 */
const MultiFeatureAnlaysisTable: React.FunctionComponent<{
  featureIds: string[];
  displayFeatureIds: string[];
  setDisplayFeatureIds: React.Dispatch<React.SetStateAction<string[]>>;
  features: ApiFeature[];
}> = ({featureIds, displayFeatureIds, setDisplayFeatureIds, features}) => {
  return (
    <div>
      {features
        .filter((feature) => !!featureIds && featureIds.includes(feature.id.toString()))
        .map((feature) => {
          return (
            <B.Button
              key={feature.id}
              active={displayFeatureIds.includes(feature.id.toString())}
              onClick={() => {
                const featureId = feature.id.toString();
                if (featureId) {
                  setDisplayFeatureIds((prev) => {
                    return prev.includes(featureId!)
                      ? prev.filter((id) => id !== featureId)
                      : [...prev, featureId];
                  });
                }
              }}
            >
              {feature.properties.name}
            </B.Button>
          );
        })}
    </div>
  );
};

export default MultiFeatureAnlaysisTable;
