import {GraphTimeRange} from 'app/components/AnalyzePolygonChart/types';

import {FeatureStatsApiResponse} from './hooks/useAggregatedFeatureStats';

export const MOCK_TIME_RANGE: GraphTimeRange = [
  new Date('2025-01-01T00:00:00Z'),
  new Date('2025-05-01T00:00:00Z'),
];

export const MOCK_RESPONSE: FeatureStatsApiResponse = {
  115281105: {
    '2025-01-01T00:00:00Z': 0.92481357371388,
    '2025-02-01T00:00:00Z': 0.5582458889404964,
    '2025-03-01T00:00:00Z': 0.20538338958886393,
    '2025-04-01T00:00:00Z': 0.5729911009545063,
    '2025-05-01T00:00:00Z': 0.6218297991201606,
  },
  115281104: {
    '2025-01-01T00:00:00Z': 0.13788674312177818,
    '2025-02-01T00:00:00Z': 0.6635287405599539,
    '2025-03-01T00:00:00Z': 0.36809309862373374,
    '2025-04-01T00:00:00Z': 0.21337799077152486,
    '2025-05-01T00:00:00Z': 0.7130967504913678,
  },
  115281103: {
    '2025-01-01T00:00:00Z': 0.4378867431217818,
    '2025-02-01T00:00:00Z': 0.2635287405599539,
    '2025-03-01T00:00:00Z': 0.9680930986233374,
    '2025-04-01T00:00:00Z': 0.11337799077152486,
    '2025-05-01T00:00:00Z': 0.3130967504913678,
  },
  115284364: {
    '2025-01-01T00:00:00Z': 0.8234567890123456,
    '2025-02-01T00:00:00Z': 0.3456789012345678,
    '2025-03-01T00:00:00Z': 0.567890123456789,
    '2025-04-01T00:00:00Z': 0.7890123456789012,
    '2025-05-01T00:00:00Z': 0.2345678901234567,
  },
  115194067: {
    '2025-01-01T00:00:00Z': 0.1234567890123456,
    '2025-02-01T00:00:00Z': 0.9876543210987654,
    '2025-03-01T00:00:00Z': 0.4567890123456789,
    '2025-04-01T00:00:00Z': 0.6789012345678901,
    '2025-05-01T00:00:00Z': 0.8901234567890123,
  },
  115194068: {
    '2025-01-01T00:00:00Z': 0.3456789012345678,
    '2025-02-01T00:00:00Z': 0.567890123456789,
    '2025-03-01T00:00:00Z': 0.7890123456789012,
    '2025-04-01T00:00:00Z': 0.9012345678901234,
    '2025-05-01T00:00:00Z': 0.1234567890123456,
  },
  115274648: {
    '2025-01-01T00:00:00Z': 0.2345678901234567,
    '2025-02-01T00:00:00Z': 0.4567890123456789,
    '2025-03-01T00:00:00Z': 0.6789012345678901,
    '2025-04-01T00:00:00Z': 0.8901234567890123,
    '2025-05-01T00:00:00Z': 0.0123456789012345,
  },
  115283926: {
    '2025-01-01T00:00:00Z': 0.1357924680135792,
    '2025-02-01T00:00:00Z': 0.2468035791246803,
    '2025-03-01T00:00:00Z': 0.3579146802357914,
    '2025-04-01T00:00:00Z': 0.4680257913468025,
    '2025-05-01T00:00:00Z': 0.579136804579136,
  },
  115283798: {
    '2025-01-01T00:00:00Z': 0.6802479135680247,
    '2025-02-01T00:00:00Z': 0.791358026791358,
    '2025-03-01T00:00:00Z': 0.8024691357902469,
    '2025-04-01T00:00:00Z': 0.913580246801358,
    '2025-05-01T00:00:00Z': 0.0246913579124691,
  },
  115202205: {
    '2025-01-01T00:00:00Z': 0.1357924680135792,
    '2025-02-01T00:00:00Z': 0.2468035791246803,
    '2025-03-01T00:00:00Z': 0.3579146802357914,
    '2025-04-01T00:00:00Z': 0.4680257913468025,
    '2025-05-01T00:00:00Z': 0.579136824579136,
  },
  114343243: {
    '2025-01-01T00:00:00Z': 0.1111111111111111,
    '2025-02-01T00:00:00Z': 0.2222222222222222,
    '2025-03-01T00:00:00Z': 0.3333333333333333,
    '2025-04-01T00:00:00Z': 0.4444444444444444,
    '2025-05-01T00:00:00Z': 0.5555555555555555,
  },
  115283877: {
    '2025-01-01T00:00:00Z': 0.6666666666666666,
    '2025-02-01T00:00:00Z': 0.7777777777777777,
    '2025-03-01T00:00:00Z': 0.8888888888888888,
    '2025-04-01T00:00:00Z': 0.9999999999999999,
    '2025-05-01T00:00:00Z': 0.1234567890123456,
  },
  114305456: {
    '2025-01-01T00:00:00Z': 0.0987654321098765,
    '2025-02-01T00:00:00Z': 0.1975308642197531,
    '2025-03-01T00:00:00Z': 0.2962962963296296,
    '2025-04-01T00:00:00Z': 0.3950617284395062,
    '2025-05-01T00:00:00Z': 0.4938271605493827,
  },
  114305457: {
    '2025-01-01T00:00:00Z': 0.5925925926592593,
    '2025-02-01T00:00:00Z': 0.6913580247691358,
    '2025-03-01T00:00:00Z': 0.7901234568790124,
    '2025-04-01T00:00:00Z': 0.888888889888889,
    '2025-05-01T00:00:00Z': 0.9876543210987654,
  },
};
